import { t } from "i18next";
import { Container, MoonAnimation } from "../components";
import { PROJECTS } from "src/config/projects";
import { useState } from "react";
import { ModalProject } from "src/components/modal-project";

export const Projects = (props: any) => {
  const [showProject, setShowProject] = useState(false);
  const [project, setProject] = useState(PROJECTS[0]);

  return (
    <Container>
      <div className="px-[30px] h-full inner-page">
        <div className="pt-[130px] max-w-[1200px] mx-auto">
          <div className="flex flex-col md:flex-row items-start">
            {/* Left Section */}
            <div className="flex-1">
              <div className="flex flex-row items-center cursor-pointer">
                <p className="txt-gradient max-w-[150px]">
                  # {t("menu.projects")}
                </p>
              </div>
              <h1 className="txt-white max-w-[650px] text-[32px] leading-[40px] md:text-[48px] md:leading-[60px] font-[900] pt-[10px]">
                {t("projects.title")}
                <br />
                {t("projects.title2")}
              </h1>
              {/* Projects */}
              <div className="mt-[50px] flex flex-row flex-wrap justify-between md:justify-start">
                {PROJECTS.map((item, i) => {
                  const { logo, name_i18n } = item;
                  return (
                    <div
                      key={i}
                      className="flex flex-col group cursor-pointer mr-[50px] items-center mb-[30px]"
                      onClick={() => {
                        setProject(item);
                        setShowProject(true);
                      }}
                    >
                      <img
                        src={logo}
                        alt={t(`projects.${name_i18n}`)}
                        className="w-[70px] rounded-full drop-shadow-md group-hover:drop-shadow-[0_25px_25px_rgba(15,153,163,0.25)] transition-all duration-700 border-[1px] border-solid bg-white"
                      />
                      <p className="mt-[10px]">{t(`projects.${name_i18n}`)}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Right Section */}
            <div
              className="mt-[50px] md:mt-[0px] p-[50px] md:p-[100px] rounded-[30px] flex justify-center items-center bg-cover"
              style={{ backgroundImage: "url('/assets/game-asset-bg.jpg')" }}
            >
              <MoonAnimation />
            </div>
          </div>
        </div>
      </div>

      <ModalProject
        isModalOpen={showProject}
        setIsModalOpen={setShowProject}
        project={project}
      />
    </Container>
  );
};
