import { t } from "i18next";
import { CicleAnimation, Container, SocialMenu } from "../components";
import { history } from "src/stores";

export const Home = (props: any) => {
  return (
    <Container>
      <div className="home py-[120px] px-[30px] h-full">
        <div className="flex flex-col justify-start items-center h-full relative z-[5] text-center">
          <h1 className="txt-gradient text-[24px]">N3 Artiverse</h1>
          <h2 className="text-[30px] mt-[20px] tracking-[5px] leading-[36px]">
            {t("home.title")}
          </h2>
          <p className="mt-[50px] max-w-[650px]">{t("home.liner")}</p>
          <div className="flex-1 flex flex-col justify-end">
            <button
              className="mt-[50px] gradient"
              onClick={() => history.push("/projects")}
            >
              {t("button.exploreProjects")}
            </button>
          </div>
        </div>
        <CicleAnimation />

        {/* Social Menu */}
        <div className="fixed bottom-[30px] right-[30px]">
          <SocialMenu />
        </div>
      </div>
    </Container>
  );
};
