import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Header } from "./header";

export const Container = (props: any) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div>
      <Header />
      {children}
    </div>
  );
};
