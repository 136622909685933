const FILE_PATH = "/assets/projects";

export const PROJECTS = [
  {
    name_i18n: "n3snap",
    desc_i18n: "n3snapDesc",
    logo: `${FILE_PATH}/n3snap.png`,
    whitePaper: `${FILE_PATH}/N3 Snap Pitch Deck v1.0.pdf`,
    instagram: "https://www.instagram.com/n3snap/",
    twitter: null,
    link: "https://n3snap.app.link/",
    demo: `${FILE_PATH}/n3snap_demo.png`,
  },
  {
    name_i18n: "nova3",
    desc_i18n: "nova3Desc",
    logo: `${FILE_PATH}/nova3_full.png`,
    whitePaper: `${FILE_PATH}/NART Pitch Deck (Chinese) _ v1.7-compressed.pdf`,
    instagram: "https://www.instagram.com/nova3.art/",
    twitter: null,
    link: "https://nova3.art/",
    demo: `${FILE_PATH}/nova3_demo.png`,
  },
  {
    name_i18n: "fortuna",
    desc_i18n: "fortunaDesc",
    logo: `${FILE_PATH}/fortuna_labs.png`,
    whitePaper: `${FILE_PATH}/Fortuna Lab Deck_v1-compressed.pdf`,
    instagram: null,
    twitter: "https://twitter.com/fortunalabs_io",
    link: "https://fortunalabs.io/",
    demo: `${FILE_PATH}/fortuna_demo.png`,
  },
  {
    name_i18n: "n3paradise",
    desc_i18n: "n3paradiseDesc",
    logo: `${FILE_PATH}/n3_paradise.png`,
    whitePaper: `${FILE_PATH}/N3_Paradise_chinese.pdf`,
    instagram: null,
    twitter: null,
    link: "https://www.spatial.io/s/Pet-Paradise-655df8970687735fdff25ff6?share=1324277952166943037",
    demo: null,
  },
  {
    name_i18n: "n3consultant",
    desc_i18n: "n3consultantDesc",
    logo: `${FILE_PATH}/default.png`,
    whitePaper: null,
    instagram: null,
    twitter: null,
    link: null,
    demo: null,
  },
];
