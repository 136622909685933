import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { t } from "i18next";
export const ModalProject = (props: any) => {
  const { isModalOpen, setIsModalOpen, project } = props;
  const { name_i18n, desc_i18n, demo, twitter, instagram, link, whitePaper } =
    project;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-black text-[16px] uppercase">
          {t(`projects.${name_i18n}`)}
        </h5>
        {/* Community Icons */}
        {(twitter || instagram) && (
          <div className="flex flex-row mt-[20px]">
            {twitter && (
              <img
                src={"/assets/twitter.png"}
                alt={t(`twitter`)}
                className={`${$socialIconStyle} mr-[50px]`}
                onClick={() => window.open(twitter)}
              />
            )}
            {instagram && (
              <img
                src={"/assets/instagram.png"}
                alt={t(`instagram`)}
                className={$socialIconStyle}
                onClick={() => window.open(instagram)}
              />
            )}
          </div>
        )}
        {/* Description */}
        <p className="mt-[10px]">{t(`projects.${desc_i18n}`)}</p>
        {/* Screenshot */}
        {demo && (
          <img
            src={demo}
            alt={t(`projects.${name_i18n}`)}
            className="w-[full] mt-[20px]"
          />
        )}
        <div className="mt-[20px] flex flex-col items-center">
          {/* To project */}
          {link && (
            <button className="gradient" onClick={() => window.open(link)}>
              {t("button.goto")} {t(`projects.${name_i18n}`)}
            </button>
          )}
          {!link && <p className="gradient-txt">{t("button.comingSoon")}</p>}
          {/* To deck */}
          {whitePaper && (
            <p
              className="cursor-pointer mt-[20px]"
              onClick={() => window.open(whitePaper)}
            >
              {t("button.downloadWP")}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

const $socialIconStyle = `w-[30px] rounded-full drop-shadow-md hover:drop-shadow-[0_25px_25px_rgba(15,153,163,0.25)] transition-all duration-700 border-[1px] border-solid bg-white cursor-pointer`;
