import { Modal } from "antd";
import React from "react";
import { CloseButton } from "./close-btn";
export const ModalComingSoon = (props: any) => {
  const { isModalOpen, setIsModalOpen } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-center align-center min-h-[200px]">
        <p className="uppercase text-center text-black">Coming Soon</p>
      </div>
    </Modal>
  );
};
