export const enTranslation = {
  home: {
    title: "The New Digital Era",
    liner:
      "We are pioneering the transformation from Web2 to Web3, crafting innovative solutions in #socialFi, #gameFi, #DeFi, and offering expert consultancy services.",
  },
  projects: {
    title: `We love the projects, we build the Community!`,
    title2: "",
    n3snap: "N3 Snap",
    n3snapDesc:
      "SNAP FOR REAL; In less than a minute, capture your most memorable moments and start earning $SNAP. #Snap-To-Earn",
    nova3: "Nova3.Art",
    nova3Desc: `「參與創作、閱讀、分享賺取 NART 代幣」

在 Nova3.Art，我們希望創造一個全新的文字社群。以往的網上創作者總有一個弊病，就是很難單憑寫作生活。 Nova3.Art 將會改變這一個生態環境。無論您是讀者，又或是網上創作者，只要您參與創作、閱讀、分享，你就能賺取我們的 NART 代幣。創作者不用再依靠出版社，讀者也更有動力去閱讀、支持創作者，亦為創作者提供一個更舒適的成名機會。`,
    fortuna: "Fortuna Labs",
    fortunaDesc:
      "Anticipate whether the cryptocurrency market will surge or decline in the upcoming moments and secure victory with a single click.",
    n3paradise: "N3 Paradise",
    n3paradiseDesc:
      "N3 Paradise provides every pet owner with a personal space to continue cherishing the wonderful moments with their beloved pets. In Pet Paradise, you will still need to take care of your pets, play with them, help keep them clean, and listen to their adorable calls.",
    n3consultant: "N3 Consultant",
    n3consultantDesc:
      "N3 Consultant is your trusted partner in the digital realm, specializing in a spectrum of services to help you realize your vision. From crafting cutting-edge websites and mobile applications to venturing into the exciting world of Web3 with metaverse development, NFT minting sites, and NFT generation, we provide comprehensive solutions that elevate your online presence. Our expertise extends to strategic marketing management, ensuring your project not only comes to life but thrives in the ever-evolving digital landscape.",
  },
  button: {
    exploreProjects: "Explore our Projects",
    goto: "Go to",
    comingSoon: "Coming Soon",
    downloadWP: "Download Whitepaper",
  },
  menu: {
    projects: "Projects",
  },
};
