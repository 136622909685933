export const tcTranslation = {
  home: {
    title: "數字新時代",
    liner:
      "我們引領Web2向Web3的轉變，打造創新解決方案，涵蓋 #社交金融、#遊戲金融、#去中心化金融等領域，同時提供專業的咨詢服務。",
  },
  projects: {
    title: `我們熱愛這些項目，`,
    title2: "我們建立社群！",
    n3snap: "N3 Snap",
    n3snapDesc:
      "真實捕捉，不到一分鐘，記錄下您最難忘的時刻，並開始賺取 $SNAP。 #捕捉賺取",
    nova3: "Nova3.Art",
    nova3Desc: `「參與創作、閱讀、分享賺取 NART 代幣」

  在 Nova3.Art，我們希望創造一個全新的文字社群。以往的網上創作者總有一個弊病，就是很難單憑寫作生活。 Nova3.Art 將會改變這一個生態環境。無論您是讀者，又或是網上創作者，只要您參與創作、閱讀、分享，你就能賺取我們的 NART 代幣。創作者不用再依靠出版社，讀者也更有動力去閱讀、支持創作者，亦為創作者提供一個更舒適的成名機會。`,
    fortuna: "Fortuna Labs",
    fortunaDesc:
      "預測加密貨幣市場在即將來臨的時刻是上漲還是下跌，並通過單擊一鍵來確保勝利。",
    n3paradise: "N3 寵物天堂",
    n3paradiseDesc:
      "N3 寵物天堂，為每位寵物飼主提供一個個人空間，以延續與他們最愛的寵物的美好時光。在寵物天堂，您仍然需要照料寵物，陪伴它們玩耍，幫助它們保持衛生，並聆聽它們可愛的呼喚。",
    n3consultant: "N3 Consultant",
    n3consultantDesc:
      "N3 Consultant是您在數字領域的信任夥伴，專注於一系列服務，幫助您實現您的願景。從打造尖端網站和移動應用程序到冒險進入Web3的激動人心領域，包括元宇宙開發、NFT鑄造站和NFT生成，我們提供全面的解決方案，提升您的線上存在感。我們的專業知識還涵蓋戰略性的營銷管理，確保您的項目不僅實現，還能在不斷變化的數字領域中蓬勃發展。",
  },
  button: {
    exploreProjects: "瀏覽我們的項目",
    goto: "前往",
    comingSoon: "即將推出",
    downloadWP: "下載白皮書",
  },
  menu: {
    projects: "項目",
  },
};
