import { t } from "i18next";
import { CloseButton } from "./close-btn";
import { history } from "src/stores";

const SUBMENU = [
  {
    name: "Privacy",
  },
  {
    name: "FAQ",
  },
  {
    name: "Careers",
  },
];

export const MobileMenu = (props: any) => {
  const { isMenuOpen, setIsMenuOpen, menu } = props;

  const redirect = (url: string) => {
    history.push(url);
    setIsMenuOpen(false);
  };

  return (
    <div
      className={`w-[100%] ${
        isMenuOpen
          ? "h-full z-[9999] overflow-hidden overflow-y-scroll"
          : "h-[0] invisible overflow-hidden"
      } fixed top-[0] left-[0] transition-all duration-300 p-[30px] bg-gradient-to-r from-[#fff] to-[#ddd] pl-[30px] md:pl-[30%] pt-[50px] md:pt-[10%]`}
    >
      {/* Close btn */}
      <CloseButton handleCancel={() => setIsMenuOpen(false)} />

      {/* Menus */}
      <div className="flex flex-col justify-start items-start">
        {menu.map((item: any, i: any) => {
          const isCurrentPage =
            window.location.pathname.indexOf(item.name.toLowerCase()) > -1;
          return (
            <div key={i} className={`pb-[30px]`}>
              <p
                className={`${
                  item?.url ? `cursor-pointer` : `cursor-not-allowed`
                } hover:text-secondary transition-all duration-500 text-[32px] ${
                  isCurrentPage ? "txt-gradient" : "text-black"
                }`}
                onClick={() => item.url && redirect(item.url)}
              >
                {t(`menu.${item.name}`)}
              </p>
            </div>
          );
        })}
      </div>

      {/* Sub Menus */}
      <div className="flex flex-row justify-start items-start pt-[50px]">
        {SUBMENU.map((item: any, i) => {
          return (
            <div key={i} className="pr-[50px] desktop">
              <p
                className={`${
                  item?.url ? `cursor-pointer` : `cursor-not-allowed`
                } hover:text-secondary transition-all duration-500 text-black`}
                onClick={() => item?.url && history.push(item.url)}
              >
                {item.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
