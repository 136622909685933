import React from "react";
import { ReactComponent as CloseIcon } from "../svg-icons/close.svg";

export const CloseButton = (props: any) => {
  const { handleCancel, position = "absolute" } = props;

  return (
    <div
      className={`${
        position === "absolute" && "absolute top-[30px] right-[30px]"
      } z-10 w-[20px] h-[20px] cursor-pointer`}
      onClick={() => handleCancel()}
    >
      <CloseIcon />
    </div>
  );
};
