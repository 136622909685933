import { useState } from "react";
import { history } from "src/stores";
import { MobileMenu } from "./mobile-menu";
import i18n from "src/i18n";
import { t } from "i18next";

export const Header = (props: any) => {
  const lang = sessionStorage.getItem("n3art-language") || "en";
  console.log("lang", lang);
  const MENU = [
    {
      name: "projects",
      url: "/projects",
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLang = (lang: string) => {
    sessionStorage.setItem("n3art-language", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  return (
    <div className="fixed bg-transparent w-full px-[30px] py-[30px] flex flex-row align-center items-center z-[100]">
      <div className="flex flex-row align-center items-center flex-1 ">
        <h2 className="cursor-pointer" onClick={() => history.push("/")}>
          N3
        </h2>
      </div>

      {/* Menu */}
      <div className="flex flex-row justify-center items-center">
        {MENU.map((item, i) => {
          const isCurrentPage =
            window.location.pathname.indexOf(item.name.toLowerCase()) > -1;
          return (
            <div key={i} className="pl-[50px] desktop">
              <p
                className={`cursor-pointer hover:text-primary transition-all duration-500 ${
                  isCurrentPage ? "txt-gradient" : ""
                }`}
                onClick={() => item.url && history.push(item.url)}
              >
                {t(`menu.${item.name}`)}
              </p>
            </div>
          );
        })}
        {/* Change Lang */}
        <div className="pl-[30px]">
          <p
            className={`cursor-pointer hover:text-primary transition-all duration-500`}
            onClick={() => changeLang(lang === "en" ? "tc" : "en")}
          >
            {lang === "en" ? "中" : "EN"}
          </p>
        </div>
        {/* Mobile Menu Icon */}
        <div className="pl-[30px]">
          <img
            src="/assets/burger-bar.png"
            alt="menu"
            className="h-[20px] cursor-pointer"
            onClick={() => setIsMenuOpen(true)}
          />
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        menu={MENU}
      />
    </div>
  );
};
